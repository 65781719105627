@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the base styles for Angular Material core. We include this here so that you only
// have to load a single css file for Angular Material in your app.
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$primary: mat.define-palette(mat.$light-blue-palette, 200);
$accent: mat.define-palette(mat.$light-blue-palette, 200, A100, A400);

// The warn palette is optional (defaults to red).
$warn: mat.define-palette(mat.$red-palette, 600);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

// Star ratings
/* Override colors by using scss variables */
$color-negative-rating: #dccd11 !default;
$color-ok-rating: #dccd11 !default;
$color-positive-rating: #dccd11 !default;
$color-default-rating: #dccd11 !default;

// TODO: uncomment once Marketplace needs to be functional
// @import '~css-star-rating/scss/star-rating';
