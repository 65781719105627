/* You can add global styles to this file, and also import other style files *//* -------------------------------------------- */
/* Custom fonts */
/* -------------------------------------------- */

/*Angular Material Themes: indigo-pink, deeppurple-amber, purple-green and pink-bluegrey*/
/*@import '@angular/material/core/theming/prebuilt/deeppurple-amber.css';*/

@font-face {
    font-family: 'SanFrancisco';
    src: url("./assets/font/san-francisco/sf-ui-display-light-webfont.woff2") format("woff2"),
         url("./assets/font/san-francisco/sf-ui-display-light-webfont.woff") format("woff"), 
         url("./assets/font/san-francisco/sf-ui-display-light-webfont.ttf") format("truetype");
    font-weight: 300;
    font-style: normal; 
}
@font-face {
    font-family: 'SanFrancisco';
    src: url("./assets/font/san-francisco/sf-ui-display-regular-webfont.woff2") format("woff2"), 
         url("./assets/font/san-francisco/sf-ui-display-regular-webfont.woff") format("woff"), 
         url("./assets/font/san-francisco/sf-ui-display-regular-webfont.ttf") format("truetype");
    font-weight: normal;
    font-style: normal; 
}    
@font-face {
    font-family: 'SanFrancisco';
    src: url("./assets/font/san-francisco/sf-ui-display-medium-webfont.woff2") format("woff2"), 
         url("./assets/font/san-francisco/sf-ui-display-medium-webfont.woff") format("woff"), 
         url("./assets/font/san-francisco/sf-ui-display-medium-webfont.ttf") format("truetype");
    font-weight: 500;
    font-style: normal; 
}
@font-face {
    font-family: 'SanFrancisco';
    src: url("./assets/font/san-francisco/sf-ui-display-semibold-webfont.woff2") format("woff2"), 
         url("./assets/font/san-francisco/sf-ui-display-semibold-webfont.woff") format("woff"), 
         url("./assets/font/san-francisco/sf-ui-display-semibold-webfont.ttf") format("truetype");
    font-weight: 600;
    font-style: normal; 
}
/* 
@font-face {
    font-family: 'SanFrancisco';
    src: url("./assets/font/san-francisco2/SF-UI-Display-Bold.otf") format("opentype");
    font-weight: 700;
    font-style: normal; 
} */

@font-face {
    font-family: 'Rift';
    src: url("./assets/font/other/Fort_Foundry_Rift_Bold_Italic.woff") format("woff"), 
         url("./assets/font/other/Fort_Foundry_Rift_Bold_Italic.otf") format('opentype');
    font-weight: 500;
    font-style: normal; 
}



/** Custom css */
/* -----------------------------
  BASE STYLE
-------------------------------- */
* {
    box-sizing: border-box;
    font-family: SanFrancisco, Helvetica, Arial, sans-serif;
    /*font-weight: normal;*/
}

html{
    height:100%;
}

body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-size: 22px;
    letter-spacing: 0.55px;
    line-height: 30px;
    color: #16232b;
    box-sizing: border-box; }

a, .regular-link {
    text-decoration: none;
    color: #4a90e2;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0.94px;
    line-height: 27px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0px; }

h1 {
    color: #16232b;
    font-size: 24px;
    letter-spacing: 0.6px;
    line-height: 33px;
    margin-top: 6px;
    font-weight: normal;
}

h2 {
    color: #16232b;
    font-size: 22px;
    letter-spacing: 0.55px;
    line-height: 30px;
    font-weight: normal;
}

h3 {
    color: #16232b;
    font-size: 20px;
    letter-spacing: 0.55px;
    line-height: 27px;
    font-weight: normal;
}

h4 {
    font-size: 24px;
    letter-spacing: 1.5px;
    font-weight: normal;
}
h5{
    font-weight: normal;
}

a:focus{
    outline: auto 3px #80ceff;
}
button:focus{
    outline: none;
}

.no-style-btn{
    background: none;
    border: none;
}

.scrollable{
    overflow: auto;
}

pre{
    font-family: SanFrancisco, Helvetica, Arial, sans-serif;
}

input:not(.mat-input-element) {
    height: 45px;
    background-color: #e2e3e7;
    border-radius: 3px;
    border: none;
    font-size: 24px;
    letter-spacing: 0.8px;
    margin: 0;
    padding: 0px 0px 0px 5px; }

input:focus {
    outline: none; }

input .modal-input {
    height: 35px; }


select {
    height: 45px;
    background-color: #e2e3e7;
    border-radius: 3px;
    border: none;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.8px;
    line-height: 33px;
    padding: 0px 5px 0px 5px; }

select:focus {
    outline: none; }

textarea {
    background-color: #e2e3e7;
    color: #152026;
    font-size: 22px;
    letter-spacing: 1.02px;
    line-height: 30px;
    border: none; }

textarea:focus {
    outline: none; }

/* The clearfix */
.group:after {
    content: "";
    display: table;
    clear: both; }

.center {
    margin-left: auto;
    margin-right: auto; }

.align-center {
    text-align: center; }

.align-left {
    text-align: left; }

.align-right {
    text-align: right; }

.relative {
    position: relative; }

.button:disabled {
    cursor: default;
    background-color: #999999; }

.flex-container {
    display: flex;
    flex-wrap: wrap; }

.flex{
    display: flex;
}

.error {
    color: red; }

.clickable{
    cursor: pointer;
}

/*Loader Component*/

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-ms-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-moz-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@-o-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

@keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg); }
    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg); } }

.loading {
    background: none;
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    margin: 10px auto; }

.loading-big {
    background: none;
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    margin: 10px auto; }

.loading-small {
    background: none;
    position: relative;
    display: block;
    margin: 10px auto;
    width: 32px;
    height: 32px; }

.loading > div, .loading-small > div, .loading-big > div {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 180px;
    box-shadow: 0 4px 0 0 #257bac;
    -ms-animation: uil-ring-anim .9s linear infinite;
    -moz-animation: uil-ring-anim .9s linear infinite;
    -webkit-animation: uil-ring-anim .9s linear infinite;
    -o-animation: uil-ring-anim .9s linear infinite;
    animation: uil-ring-anim .9s linear infinite; }

/* -------------------------------------------- */
/* ------------------Grid System---------------

/*This works great with the column formats*/
.flex-my-children{
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
}

.foxygrid {
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding: 0px;
    width: 100%; }

.foxygrid .row-fluid {
    width: 100%; }

.foxygrid .row-90 {
    width: 90%; }

.foxygrid .row-80 {
    width: 80%; }

.foxygrid .row-fluid:after, .foxygrid .row-90:after, .foxygrid .row-80:after {
    content: "";
    display: table;
    clear: both; }

.foxygrid .row-fluid:after {
    content: "";
    display: table;
    clear: both; }

.foxygrid .wrap-col {
    padding: 3%; }

.foxygrid .col-1-2, .foxygrid .col-1-3, .foxygrid .col-2-3, .foxygrid .col-1-4, .foxygrid .col-2-4, .foxygrid .col-3-4, .foxygrid .col-1-5, .foxygrid .col-2-5, .foxygrid .col-3-5, .foxygrid .col-4-5, .foxygrid .col-1-6, .foxygrid .col-2-6, .foxygrid .col-3-6, .foxygrid .col-4-6, .foxygrid .col-5-6, .foxygrid .col-1-12, .foxygrid .col-2-12, .foxygrid .col-3-12, .foxygrid .col-4-12, .foxygrid .col-5-12, .foxygrid .col-6-12, .foxygrid .col-7-12, .foxygrid .col-8-12, .foxygrid .col-9-12, .foxygrid .col-10-12, .foxygrid .col-11-12 {
    box-sizing: border-box;
    display: inline-block;
    float: left; }

.foxygrid .col-full {
    width: 100%;
    clear: both;
}

.foxygrid .col-1-2 {
    width: 50%; }

.foxygrid .col-1-3 {
    width: 33.33%; }

.foxygrid .col-2-3 {
    width: 66.66%; }

.foxygrid .col-1-4 {
    width: 25%; }

.foxygrid .col-2-4 {
    width: 50%; }

.foxygrid .col-3-4 {
    width: 75%; }

.foxygrid .col-1-5 {
    width: 20%; }

.foxygrid .col-2-5 {
    width: 40%; }

.foxygrid .col-3-5 {
    width: 60%; }

.foxygrid .col-4-5 {
    width: 80%; }

.foxygrid .col-1-6 {
    width: 16.66%; }

.foxygrid .col-2-6 {
    width: 33.33%; }

.foxygrid .col-3-6 {
    width: 50%; }

.foxygrid .col-4-6 {
    width: 66.66%; }

.foxygrid .col-5-6 {
    width: 83.33%; }

.foxygrid .col-1-12 {
    width: 8.333333333333333333333333333333%; }

.foxygrid .col-2-12 {
    width: 16.666666666666666666666666666667%; }

.foxygrid .col-3-12 {
    width: 25%; }

.foxygrid .col-4-12 {
    width: 33.333333333333333333333333333333%; }

.foxygrid .col-5-12 {
    width: 41.666666666666666666666666666667%; }

.foxygrid .col-6-12 {
    width: 50%; }

.foxygrid .col-7-12 {
    width: 58.333333333333333333333333333333%; }

.foxygrid .col-8-12 {
    width: 66.666666666666666666666666666667%; }

.foxygrid .col-9-12 {
    width: 75%; }

.foxygrid .col-10-12 {
    width: 83.333333333333333333333333333333%; }

.foxygrid .col-11-12 {
    width: 91.666666666666666666666666666667%; }

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .foxygrid {
        width: 100%; } }

@media only screen and (max-width: 767px) {
    .foxygrid {
        width: 100%; } }

/*@media only screen and (min-width: 620px) and (max-width: 767px) {*/
@media only screen and (min-width: 700px) and (max-width: 900px) {
    .foxygrid {
        width: 100%; }
    .foxygrid .col-1-6, .foxygrid .col-2-6, .foxygrid .col-3-6, .foxygrid .col-4-6, .foxygrid .col-5-6 {
        width: 33.33%; } }

/*@media only screen and (min-width: 400px) and (max-width: 619px) {*/
@media only screen and (min-width: 400px) and (max-width: 700px) {
    .foxygrid {
        width: 100%; }
    .foxygrid .col-1-2 {
        width: 100%; }
    .foxygrid .col-1-3, .foxygrid .col-2-3 {
        width: 100%; }
    .foxygrid .col-1-4 {
        width: 50%; }
    .foxygrid .col-1-5, .foxygrid .col-2-5, .foxygrid .col-3-5, .foxygrid .col-4-5 {
        width: 50%; }
    .foxygrid .col-1-6, .foxygrid .col-2-6, .foxygrid .col-3-6, .foxygrid .col-4-6, .foxygrid .col-5-6 {
        width: 50%; }
    .foxygrid .row-90, .foxygrid .row-80 {
        width: 100%; } }

@media only screen and (max-width: 399px) {
    .foxygrid .col-1-2, .foxygrid .col-1-3, .foxygrid .col-2-3, .foxygrid .col-1-4, .foxygrid .col-2-4, .foxygrid .col-3-4, .foxygrid .col-1-5, .foxygrid .col-2-5, .foxygrid .col-3-5, .foxygrid .col-4-5, .foxygrid .col-1-6, .foxygrid .col-2-6, .foxygrid .col-3-6, .foxygrid .col-4-6, .foxygrid .col-5-6, .foxygrid .col-1-12, .foxygrid .col-2-12, .foxygrid .col-3-12, .foxygrid .col-4-12, .foxygrid .col-5-12, .foxygrid .col-6-12, .foxygrid .col-7-12, .foxygrid .col-8-12, .foxygrid .col-9-12, .foxygrid .col-10-12, .foxygrid .col-11-12, .foxygrid .row-90, .foxygrid .row-80 {
        width: 100%; } }

/* -----------------------------
  FORM STACK
-------------------------------- */
.form-stack input {
    box-sizing: border-box;
    width: 100%;
    height: 59px;
    margin: 0;
    padding: 0px 20px;
    display: block;
    color: #747474;
    font-size: 28px;
    font-weight: 400;
    letter-spacing: 1.3px;
    line-height: 38px;
    background-color: rgba(255, 255, 255, 0.9);
    border: none; }

.form-stack input:not(:last-child) {
    border-radius: 0px;
    border-bottom: 1px solid #979797; }

.form-stack input:first-child {
    border-radius: 8px 8px 0px 0px; }

.form-stack input:last-child {
    border-radius: 0px 0px 8px 8px; }

.form-stack input:only-child {
    border-radius: 8px 8px 8px 8px; }

.pad-container {
    padding: 10px; }

.row-fluid-padded {
    margin-left: 5%;
    margin-right: 5%; }

/* -----------------------------
  admin
-------------------------------- */

.thumbnail .title {
    font-size: 16px;
    letter-spacing: 1.5px; }

.thumbnail img {
    width: 100%; }

.section-padding {
    padding-left: 2%;
    padding-right: 2%; }

/* -------------------------------------------- */
/* --------- CSS TRICKS ----------------------- */
.admin-app {
    width: 100%;
    height: 100%; }

.no-scroll{
    overflow: hidden;
}

 /*new loading page*/
.xion-landing-background {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    align-items: center;
    display: flex;
}
#grey-bar{
    display: none;
}
.xion-landing-fake-menu{
    width: 100%;
    position: absolute;
    top: 0;
    background: white;
}
.xion-landing-fake-menu img{
    float: left;
    margin: 14px 39px;
    width: 140px;
}


.mobile{
    display: none;
}
.non-mobile{
    display: block;
}


/**
* Angular material styling
*/

mat-form-field{
    width: 100%;
}
mat-form-field,
mat-select,
input.mat-input-element,
.mat-form-field-label,
textarea.mat-input-element {
    font-size: 20px !important;
    line-height: 1.4;
}

xion-create-new-store mat-form-field,
xion-create-new-store input.mat-input-element,
xion-create-new-store .mat-form-field-label{
    font-size: 22px !important;
}

.mat-form-field-label,
.mat-select-disabled .mat-select-value,
.mat-select-arrow{
    color: rgba(0,0,0,.38);
}

/*Placeholder scale and position*/
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1em) scale(0.65) perspective(100px) translateZ(0.001px);
}
/*.mat-form-field{*/
    /*margin-bottom: -20px;*/
/*}*/

.mat-form-field-appearance-legacy .mat-form-field-underline{
    bottom: .6em;
    background-color: rgba(0, 0, 0, 0.16);
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper,
.mat-form-field-wrapper {
    padding-bottom: .4em;
}

/*Filters*/
xion-search-filters mat-checkbox .mat-checkbox-frame{
    background-color: #e2e3e7;
    border: none;
}


/**
* Main media query
*/

@media only screen and (max-width: 900px) {
    #grey-bar{
        display: block;
        height: 32px;
        background: #edeeee;
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {

    .xion-landing-fake-menu img{
        float: none !important;
        display: block;
        margin: 18px auto !important;
        width: 79px !important;
    }

    /**
    * Main Header
    */

    .mobile{
        display: block;
    }
    .non-mobile{
        display: none !important;
    }


    .mynavbar-greeting a {
        font-size: 7px; }
    .mynavbar-greeting button {
        font-size: inherit;
        background: transparent;
        color: white;
        padding: 0;
        margin: -10px 0px 0px 0px;
        border: none;
        cursor: pointer; }
    .mynavbar-greeting button:focus {
        outline: none; }
    .mynavbar-greeting-your-account-down-arrow {
        width: 4px;
        height: 3px;
        margin-left: 2.5px; }
    .mynavbar-cart {
        margin-top: 8px;
        margin-left: 7px;
        margin-right: 9px; }
    .mynavbar-cart > a > img {
        width: 20px;
        height: 20px; }
    .mynavbar-cart > a > span {
        left: 6px;
        top: -3px;
        font-size: 9px; }

    /**
    * end of main header
    */
}
